export default [
  {
    path: '/portfolios',
    component: () =>
      import(/* webpackChunkName: "Portfolios" */ '@/views/Portfolios/'),
    children: [
      {
        path: '',
        name: 'Portfolios',
        redirect: '/portfolios/list',
      },
      {
        path: 'archive',
        name: 'PortfolioArchive',
        component: () =>
          import(
            /* webpackChunkName: "PortfolioArchive" */ '@/views/Portfolios/Archive'
          ),
      },
      {
        path: 'list',
        name: 'PortfolioList',
        component: () =>
          import(
            /* webpackChunkName: "PortfolioList" */ '@/views/Portfolios/List'
          ),
        children: [
          {
            name: 'PortfolioDetail',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "PortfolioDetail" */ '@/views/Portfolios/Details.vue'
              ),
            props: true,
          },
          {
            name: 'PortfolioEdit',
            path: ':id/edit',
            component: () =>
              import(
                /* webpackChunkName: "PortfolioEdit" */ '@/views/Portfolios/Edit.vue'
              ),
            props: true,
          },
        ],
      },
      {
        path: 'print',
        name: 'PortfolioPrint',
        component: () =>
          import(
            /* webpackChunkName: "PortfolioPrint" */ '@/views/Portfolios/Print'
          ),
      },
      {
        path: 'tasks',
        name: 'PortfolioTasks',
        component: () =>
          import(
            /* webpackChunkName: "PortfolioTasks" */ '@/views/Portfolios/Tasks'
          ),
      },
      {
        path: 'courses',
        name: 'PortfolioCourses',
        component: () =>
          import(
            /* webpackChunkName: "PortfolioCourses" */ '@/views/Portfolios/Courses'
          ),
      },
      {
        path: 'creation',
        name: 'PortfolioCreation',
        component: () =>
          import(
            /* webpackChunkName: "PortfolioCreation" */ '@/views/Portfolios/Creation'
          ),
      },
      {
        path: 'placements',
        name: 'PortfolioPlacements',
        component: () =>
          import(
            /* webpackChunkName: "PortfolioPlacements" */ '@/views/Portfolios/Placements'
          ),
      },
      {
        path: 'types',
        name: 'PortfolioTypeList',
        component: () =>
          import(
            /* webpackChunkName: "PortfolioTypeList" */ '@/views/Portfolios/TypeList'
          ),
        children: [
          {
            name: 'PortfolioTypeDetails',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "PortfolioTypeDetails" */ '@/views/Portfolios/TypeDetails.vue'
              ),
            props: true,
          },
          {
            name: 'PortfolioTypeEdit',
            path: ':id/edit',
            component: () =>
              import(
                /* webpackChunkName: "PortfolioTypeEdit" */ '@/views/Portfolios/TypeEdit.vue'
              ),
            props: true,
          },
        ],
      },
    ],
  },
];
