<template>
  <span>
    <v-list nav dense v-for="group in menugroups" :key="group.title">
      <v-list-group v-model="group.visible" :color="group.color">
        <template v-slot:activator>
          <v-list-item-icon v-if="!$vuetify.breakpoint.mdAndUp">
            <v-icon :color="group.color">{{ group.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-else
            ><span :class="group.color + '--text'">{{
              group.title
            }}</span></v-list-item-title
          >
        </template>
        <v-list-item
          v-for="item in group.items"
          :key="item.title"
          link
          :to="item.link"
          :disabled="!item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </span>
</template>
<script>
export default {
  props: {
    menu: { type: Array },
  },
  data() {
    return {
      menugroups: [],
    };
  },
  created() {
    this.initMenu();
  },
  watch: {
    menu() {
      this.initMenu();
    },
    menugroups: {
      deep: true,
      handler() {
        this.menugroups.forEach((group) => {
          if (group.visible) {
            localStorage.setItem("menuGroup" + group.title, "true");
          } else {
            localStorage.removeItem("menuGroup" + group.title);
          }
        });
      },
    },
  },
  methods: {
    initMenu() {
      this.menugroups = this.menu.filter(
        (group) => !group.roles || this.$_hasRole(group.roles)
      );
      this.menugroups.forEach((group) => {
        if (localStorage.getItem("menuGroup" + group.title)) {
          group.visible = true;
        } else {
          group.visible = false;
        }
      });
    },
  },
};
</script>
