export default [
  {
    path: '/lockers',
    component: () => import(/* webpackChunkName: "Locker" */ '@/views/Locker/'),
    children: [
      {
        path: '',
        name: 'Lockers',
        redirect: '/lockers/list',
      },
      {
        name: 'LockersList',
        path: 'list',
        component: () =>
          import(
            /* webpackChunkName: "LockersList" */ '@/views/Locker/List.vue'
          ),
        props: true,
        children: [
          {
            name: 'LockerDetails',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "LockerDetails" */ '@/views/Locker/LockerDetails.vue'
              ),
            props: true,
          },
        ],
      },
      {
        name: 'LockersStudents',
        path: 'students',
        component: () =>
          import(
            /* webpackChunkName: "LockersStudents" */ '@/views/Locker/Students.vue'
          ),
        props: true,
        children: [
          {
            name: 'LockerStudentDetails',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "LockersStudentDetails" */ '@/views/Locker/StudentDetails.vue'
              ),
            props: true,
          },
        ],
      },
      {
        name: 'LockersReports',
        path: 'reports',
        component: () =>
          import(
            /* webpackChunkName: "LockersReports" */ '@/views/Locker/Reports.vue'
          ),
      },
    ],
  },
];
