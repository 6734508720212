import { loadChildRoute, saveChildRoute } from './helper';

export default [
  {
    path: '/changes',

    component: () =>
      import(/* webpackChunkName: "Changes" */ '@/views/Changes/'),
    children: [
      {
        path: '',
        beforeEnter: (to, from, next) =>
          loadChildRoute(to, next, { name: 'ChangesView' }),
      },
      {
        name: 'ChangesFetch',
        path: 'fetch',
        component: () =>
          import(
            /* webpackChunkName: "ChangesFetch" */ '@/views/Changes/ChangesFetch.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
      {
        name: 'ChangesView',
        path: 'view',
        component: () =>
          import(
            /* webpackChunkName: "ChangesView" */ '@/views/Changes/ChangesView.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
      {
        name: 'ChangesExecute',
        path: 'execute',
        component: () =>
          import(
            /* webpackChunkName: "ChangesExecute" */ '@/views/Changes/ChangesExecute.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
      {
        name: 'ChangesLock',
        path: 'lock',
        component: () =>
          import(
            /* webpackChunkName: "ChangesLock" */ '@/views/Changes/ChangesLock.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
    ],
  },
];
