import { loadChildRoute, saveChildRoute } from "./helper";

export default [
  {
    path: "/log",
    component: () => import(/* webpackChunkName: "Log" */ "@/views/Log/"),
    children: [
      {
        path: "",
        name: "Log",
        beforeEnter: (to, from, next) =>
          loadChildRoute(to, next, {
            name: "LogError",
          }),
      },
      {
        path: "errors",
        name: "LogError",
        component: () =>
          import(/* webpackChunkName: "LogError" */ "@/views/Log/Errors.vue"),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
      {
        path: "person/:id",
        name: "LogPerson",
        component: () =>
          import(/* webpackChunkName: "LogPerson" */ "@/views/Log/Person.vue"),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        path: "resource",
        name: "LogResource",
        component: () =>
          import(
            /* webpackChunkName: "LogResource" */ "@/views/Log/Resource.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
      {
        path: "usage",
        name: "LogResourceUsage",
        component: () =>
          import(
            /* webpackChunkName: "LogResourceUsage" */ "@/views/Log/ResourceUsage.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
    ],
  },
];
