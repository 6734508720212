//import { loadChildRoute, saveChildRoute } from './helper';

export default [
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/views/Documents/'),
    children: [
      {
        path: 'search/:search?',
        name: 'DocumentSearch',
        component: () => import('@/views/Documents/Search.vue'),
        props: ({ params }) => ({
          search: params.search || '',
        }),
      },
      {
        path: 'noFiles',
        name: 'DocumentNoFiles',
        component: () => import('@/views/Documents/NoFiles.vue'),
      },
      {
        path: ':catId',
        name: 'Category',
        component: () => import('@/views/Documents/Category.vue'),
        props: ({ params }) => ({
          catId: Number.parseInt(params.catId, 10) || 0,
        }),
        children: [
          {
            path: 'edit/:docId',
            name: 'DocumentEdit',
            component: () => import('@/views/Documents/Edit.vue'),
            props: ({ params }) => ({
              docId: Number.parseInt(params.docId, 10) || 0,
              catId: Number.parseInt(params.catId, 10) || 0,
            }),
          },
          {
            path: 'upload/:docId',
            name: 'DocumentUpload',
            component: () => import('@/views/Documents/Upload.vue'),
            props: ({ params }) => ({
              docId: Number.parseInt(params.docId, 10) || 0,
            }),
          },
        ],
      },
    ],
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/Index/'),
    children: [
      {
        path: ':id',
        name: 'IndexDetails',
        component: () => import('@/views/Index/Details.vue'),
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        children: [
          {
            path: 'edit',
            name: 'IndexEdit',
            component: () => import('@/views/Index/Edit.vue'),
            props: ({ params }) => ({
              id: Number.parseInt(params.id, 10) || 0,
            }),
          },
        ],
      },
    ],
  },
];
