import { loadChildRoute, saveChildRoute } from './helper';

export default [
  {
    name: 'TeacherAbsence',
    path: '/teacher/absence',
    component: () => import('@/views/teacher/Absences/'),
    children: [
      {
        name: 'TeacherAbsenceDetails',
        path: ':id',
        component: () => import('@/views/teacher/Absences/Details.vue'),
        props: true,
      },
    ],
  },
  {
    name: 'TeacherCode',
    path: '/teacher/code',
    component: () => import('@/views/teacher/Codes/'),
  },
  {
    path: '/teacher/department',
    component: () => import('@/views/teacher/Departments/'),
    children: [
      {
        path: '',
        name: 'TeacherDepartment',
        beforeEnter: (to, from, next) =>
          loadChildRoute(to, next, '/teacher/department/0'),
      },
      {
        name: 'TeacherDepartmentDetail',
        path: ':id',
        component: () => import('@/views/teacher/Departments/Detail.vue'),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
    ],
  },
  {
    name: 'TeacherStage',
    path: '/teacher/stage',
    component: () => import('@/views/teacher/Stages/'),
    children: [
      {
        name: 'StageDetails',
        path: ':id',
        component: () => import('@/views/teacher/Stages/Details.vue'),
        props: true,
      },
    ],
  },
  {
    name: 'TeacherTemporary',
    path: '/teacher/temporary',
    component: () => import('@/views/teacher/Temporary/'),
  },
];
