import { loadChildRoute, saveChildRoute } from './helper';

export default [
  {
    path: '/inventory',
    component: () =>
      import(/* webpackChunkName: "Inventory" */ '@/views/Inventory/'),
    children: [
      {
        path: '',
        beforeEnter: (to, from, next) =>
          loadChildRoute(to, next, { name: 'InventoryDevices' }),
      },
      {
        name: 'InventoryDevices',
        path: 'devices',
        component: () =>
          import(
            /* webpackChunkName: "InventoryDevices" */ '@/views/Inventory/Devices.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        children: [
          {
            name: 'InventoryDevice',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "InventoryDevice" */ '@/views/Inventory/Device.vue'
              ),
            props: true,
          },
          {
            name: 'InventoryDeviceEdit',
            path: ':id/edit',
            component: () =>
              import(
                /* webpackChunkName: "InventoryDeviceEdit" */ '@/views/Inventory/DeviceEdit.vue'
              ),
            props: true,
          },
          {
            name: 'InventoryDeviceNew',
            path: ':id/new',
            component: () =>
              import(
                /* webpackChunkName: "InventoryDeviceNew" */ '@/views/Inventory/DeviceNew.vue'
              ),
            props: true,
          },
        ],
        props: true,
      },
      {
        name: 'InventoryBrandModels',
        path: 'brandmodels',
        component: () =>
          import(
            /* webpackChunkName: "InventoryBrandModels" */ '@/views/Inventory/BrandModels.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'InventoryCompanies',
        path: 'companies',
        component: () =>
          import(
            /* webpackChunkName: "InventoryCompanies" */ '@/views/Inventory/Companies.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        children: [
          {
            name: 'InventoryCompany',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "InventoryCompany" */ '@/views/Inventory/Company.vue'
              ),
            props: true,
          },
          {
            name: 'InventoryCompanyEdit',
            path: ':id/edit',
            component: () =>
              import(
                /* webpackChunkName: "InventoryCompanyEdit" */ '@/views/Inventory/CompanyEdit.vue'
              ),
            props: true,
          },
        ],
      },
      {
        name: 'InventoryDeviceTypes',
        path: 'devicetypes',
        component: () =>
          import(
            /* webpackChunkName: "InventoryDeviceTypes" */ '@/views/Inventory/DeviceTypes.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'InventoryDeviceLoans',
        path: 'loans',
        component: () =>
          import(
            /* webpackChunkName: "InventoryDeviceLoans" */ '@/views/Inventory/Loans.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'InventoryDeviceServices',
        path: 'services',
        component: () =>
          import(
            /* webpackChunkName: "InventoryDeviceServices" */ '@/views/Inventory/Services.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
    ],
  },
];
