export default [
  {
    path: '/thesis',
    component: () => import(/* webpackChunkName: "Thesis" */ '@/views/Thesis/'),
    children: [
      {
        name: 'ThesisSettings',
        path: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "ThesisSettings" */ '@/views/Thesis/Settings.vue'
          ),
      },
    ],
  },
];
