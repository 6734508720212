//import { loadChildRoute, saveChildRoute } from './helper';

export default [
  {
    path: '/optional',

    component: () =>
      import(/* webpackChunkName: "Optional" */ '@/views/Optional/'),
    children: [
      {
        path: '',
        redirect: 'availability',
      },
      {
        name: 'OptionalAvailability',
        path: 'availability',
        component: () =>
          import(
            /* webpackChunkName: "OptionalAvailability" */ '@/views/Optional/Availability.vue'
          ),
      },
      {
        name: 'OptionalChoice',
        path: 'choice',
        component: () =>
          import(
            /* webpackChunkName: "OptionalChoice" */ '@/views/Optional/Choice.vue'
          ),
        children: [
          {
            name: 'OptionalChoiceDetails',
            path: 'details/:student',
            component: () =>
              import(
                /* webpackChunkName: "OptionalChoiceDetails" */ '@/views/Optional/Details.vue'
              ),
            props: true,
          },
        ],
      },
      {
        name: 'OptionalSettings',
        path: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "OptionalSettings" */ '@/views/Optional/Settings.vue'
          ),
      },
      {
        name: 'OptionalStatistics',
        path: 'statistics',
        component: () =>
          import(
            /* webpackChunkName: "OptionalStatistics" */ '@/views/Optional/Statistics.vue'
          ),
      },
      {
        name: 'SciencePlusAvailability',
        path: 'sciencePlusAvailability',
        component: () =>
          import(
            /* webpackChunkName: "SciencePlusAvailability" */ '@/views/Optional/SciencePlusAvailability.vue'
          ),
      },
    ],
  },
];
