import "@mdi/font/css/materialdesignicons.css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// Locale
import de from "vuetify/lib/locale/de";

// Global Components
import Icon from "@/components/global/Icon.vue";
import ListIcon from "@/components/global/ListIcon.vue";
import Todo from "@/components/global/Todo.vue";

Vue.use(Vuetify);

Vue.component("Icon", Icon);
Vue.component("ListIcon", ListIcon);
Vue.component("Todo", Todo);

export default new Vuetify({
  lang: {
    current: "de",
    locales: { de },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#3348B5",
        secondary: "#41A7D1",
        accent: "#E8CC00",
        error: "#FCA400",
        info: "#41A7D1",
        success: "#74BF3D",
        warning: "#E8CC00",
        danger: "#ea4335",
        grey: "#666666",
      },
      dark: {
        primary: "#3348B5",
        secondary: "#41A7D1",
        accent: "#E8CC00",
        error: "#FCA400",
        info: "#41A7D1",
        success: "#74BF3D",
        warning: "#E8CC00",
        danger: "#ea4335",
        grey: "#666666",
      },
    },
  },
});
