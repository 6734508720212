export default [
  {
    path: '/signage',
    component: () => import(/* webpackChunkName: "Screen" */ '@/views/Screen/'),
    children: [
      {
        path: '',
        name: 'Screen',
        redirect: 'screens',
      },
      {
        path: 'preview',
        name: 'ScreenPreview',
        component: () =>
          import(
            /* webpackChunkName: "ScreenPreview" */ '@/views/Screen/Preview'
          ),
      },
      {
        path: 'screens',
        name: 'ScreenScreens',
        component: () =>
          import(
            /* webpackChunkName: "ScreenScreens" */ '@/views/Screen/Screens'
          ),
        children: [
          {
            path: ':id',
            name: 'ScreenScreen',
            component: () =>
              import(
                /* webpackChunkName: "ScreenScreen" */ '@/views/Screen/Screen'
              ),
            props: true,
          },
        ],
      },
      {
        path: 'settings',
        name: 'ScreenSettings',
        component: () =>
          import(
            /* webpackChunkName: "ScreenSettings" */ '@/views/Screen/Settings'
          ),
      },
    ],
  },
];
