import { loadChildRoute, saveChildRoute } from './helper';

export default [
  {
    path: '/documentation',
    component: () =>
      import(/* webpackChunkName: "Documentation" */ '@/views/Documentation/'),
    children: [
      {
        path: '',
        beforeEnter: (to, from, next) =>
          loadChildRoute(to, next, { name: 'DocumentationComponents' }),
      },
      {
        name: 'DocumentationGlobal',
        path: 'global',
        component: () =>
          import(
            /* webpackChunkName: "DocumentationGlobal" */ '@/views/Documentation/Global.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
      {
        name: 'DocumentationComponents',
        path: 'components',
        component: () =>
          import(
            /* webpackChunkName: "DocumentationComponents" */ '@/views/Documentation/Components.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
      {
        name: 'DocumentationForms',
        path: 'forms',
        component: () =>
          import(
            /* webpackChunkName: "DocumentationForms" */ '@/views/Documentation/Forms.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
      {
        name: 'DocumentationNotes',
        path: 'notes',
        component: () =>
          import(
            /* webpackChunkName: "DocumentationNotes" */ '@/views/Documentation/Notes.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
      {
        name: 'DocumentationTools',
        path: 'tools',
        component: () =>
          import(
            /* webpackChunkName: "DocumentationTools" */ '@/views/Documentation/Tools.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
      },
    ],
  },
];
