export default [
  {
    path: '/worklogs',
    name: 'Worklogs',
    component: () =>
      import(/* webpackChunkName: "Worklogs" */ '@/views/Worklogs/'),
    children: [
      {
        name: 'WorklogDetail',
        path: ':id',
        component: () =>
          import(
            /* webpackChunkName: "WorklogDetail" */ '@/views/Worklogs/Details.vue'
          ),
        props: true,
      },
      {
        name: 'WorklogEdit',
        path: ':id/edit',
        component: () =>
          import(
            /* webpackChunkName: "WorklogEdit" */ '@/views/Worklogs/Edit.vue'
          ),
        props: true,
      },
    ],
  },
];
