import { loadChildRoute, saveChildRoute } from './helper';

export default [
  {
    path: '/schoolclasses',
    beforeEnter: (to, from, next) =>
      loadChildRoute(to, next, '/schoolclasses/0'),
  },
  {
    path: '/schoolclasses/:id',
    component: () =>
      import(/* webpackChunkName: "SchoolClasses" */ '@/views/SchoolClasses/'),

    children: [
      {
        path: '',
        name: 'SchoolClass',
        beforeEnter: (to, from, next) =>
          loadChildRoute(to, next, '/schoolclasses/0/list'),
        props: true,
      },
      {
        name: 'SchoolClassAbsences',
        path: 'absences',
        component: () =>
          import(
            /* webpackChunkName: "SchoolClassAbsences" */ '@/views/SchoolClasses/Absences.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'SchoolClassContact',
        path: 'contact',
        component: () =>
          import(
            /* webpackChunkName: "SchoolClassContact" */ '@/views/SchoolClasses/Contact.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'SchoolClassCourses',
        path: 'courses',
        component: () =>
          import(
            /* webpackChunkName: "SchoolClassCourses" */ '@/views/SchoolClasses/Courses.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'SchoolClassList',
        path: 'list',
        component: () =>
          import(
            /* webpackChunkName: "SchoolClassList" */ '@/views/SchoolClasses/List.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'SchoolClassLocker',
        path: 'locker',
        component: () =>
          import(
            /* webpackChunkName: "SchoolClassLocker" */ '@/views/SchoolClasses/Locker.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'SchoolClassPortrait',
        path: 'portrait',
        component: () =>
          import(
            /* webpackChunkName: "schoolclassesportrait" */ '@/views/SchoolClasses/Portrait.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'SchoolClassReport',
        path: 'report',
        component: () =>
          import(
            /* webpackChunkName: "SchoolClassReport" */ '@/views/SchoolClasses/Report.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
    ],
    props: true,
  },
];
