import { loadChildRoute, saveChildRoute } from "./helper";

export default [
  {
    path: "/people",
    redirect: "people/0",
  },
  {
    path: "/people/:id",
    component: () => import(/* webpackChunkName: "People" */ "@/views/People/"),

    children: [
      {
        path: "",
        name: "Person",
        beforeEnter: (to, from, next) =>
          loadChildRoute(to, next, {
            name: "PersonContact",
            params: { id: to.params.id },
          }),
        props: true,
      },
      {
        name: "PersonAbsences",
        path: "absences",
        component: () =>
          import(
            /* webpackChunkName: "PersonAbsences" */ "@/views/People/Absences.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
        children: [
          {
            name: "PersonAbsencesJustification",
            path: ":justificationId",
            component: () =>
              import(
                /* webpackChunkName: "JustificationDetail" */ "@/views/People/JustificationDetail.vue"
              ),

            props: true,
          },
        ],
      },
      {
        name: "PersonAbsencesTeacher",
        path: "absencesTeacher",
        component: () =>
          import(
            /* webpackChunkName: "PersonAbsencesTeacher" */ "@/views/People/AbsencesTeacher.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: "PersonAccount",
        path: "account",
        component: () =>
          import(
            /* webpackChunkName: "PersonAccount" */ "@/views/People/Account.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: "PersonContact",
        path: "contact",
        component: () =>
          import(
            /* webpackChunkName: "PersonContact" */ "@/views/People/Contact.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: "PersonCourses",
        path: "courses",
        component: () =>
          import(
            /* webpackChunkName: "PersonCourses" */ "@/views/People/Courses.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: "PersonGroups",
        path: "groups",
        component: () =>
          import(
            /* webpackChunkName: "PersonGroups" */ "@/views/People/Groups.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },

      {
        name: "PersonPortfolios",
        path: "portfolios",
        component: () =>
          import(
            /* webpackChunkName: "PersonPortfolios" */ "@/views/People/Portfolios.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: "PersonPrint",
        path: "print",
        component: () =>
          import(
            /* webpackChunkName: "PersonPrint" */ "@/views/People/Print.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: "PersonProfile",
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "PersonProfile" */ "@/views/People/Profile.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: "PersonReport",
        path: "report",
        component: () =>
          import(
            /* webpackChunkName: "PersonReport" */ "@/views/People/Report.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: "PersonLog",
        path: "log",
        component: () =>
          import(
            /* webpackChunkName: "PersonLog" */ "@/views/People/ChangeLog.vue"
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
    ],
    props: true,
  },
];
