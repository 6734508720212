export default [
  {
    path: "/lessonChanges",
    component: () =>
      import(/* webpackChunkName: "LessonChanges" */ "@/views/LessonChanges/"),
    name: "LessonChanges",
    children: [
      {
        path: "schoolClass/:id",
        name: "LessonChangesSchoolclass",
        component: () =>
          import(
            /* webpackChunkName: "LessonChangesSchoolclass" */ "@/views/LessonChanges/SchoolClass"
          ),
      },
    ],
  },
];
