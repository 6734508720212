import { loadChildRoute, saveChildRoute } from './helper';

export default [
  {
    path: '/courses',
    beforeEnter: (to, from, next) => loadChildRoute(to, next, '/courses/0'),
  },
  {
    path: '/courses/:id',
    component: () =>
      import(/* webpackChunkName: "Courses" */ '@/views/Courses/'),

    children: [
      {
        path: '',
        name: 'Course',
        beforeEnter: (to, from, next) =>
          loadChildRoute(to, next, '/courses/0/portrait'),
      },
      {
        name: 'CourseList',
        path: 'list',
        component: () =>
          import(
            /* webpackChunkName: "CourseList" */ '@/views/Courses/List.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'CoursePortrait',
        path: 'portrait',
        component: () =>
          import(
            /* webpackChunkName: "CoursePortrait" */ '@/views/Courses/Portrait.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
      {
        name: 'CourseReport',
        path: 'report',
        component: () =>
          import(
            /* webpackChunkName: "CourseReport" */ '@/views/Courses/Report.vue'
          ),
        beforeEnter: (to, from, next) => saveChildRoute(to, next),
        props: true,
      },
    ],
    props: true,
  },
];
