export default [
  {
    path: '/financeEvents',
    name: 'FinanceEvents',
    component: () =>
      import(/* webpackChunkName: "financeEvents" */ '@/views/FinanceEvents/'),
    children: [
      {
        name: 'FinnaceEventDetail',
        path: ':id',
        component: () =>
          import(
            /* webpackChunkName: "FinnaceEventDetail" */ '@/views/FinanceEvents/Detail.vue'
          ),
        props: true,
      },
    ],
  },
  {
    path: '/financePrints',
    name: 'FinancePrints',
    component: () =>
      import(/* webpackChunkName: "FinancePrints" */ '@/views/FinancePrints/'),
    props: true,
    children: [
      {
        name: 'FinancePrintsDetail',
        path: ':id',
        component: () =>
          import(
            /* webpackChunkName: "FinancePrintsDetail" */ '@/views/FinancePrints/Detail.vue'
          ),
        props: true,
      },
    ],
  },
  {
    path: '/financeInvoices',
    component: () =>
      import(
        /* webpackChunkName: "FinanceInvoices" */ '@/views/FinanceInvoices/'
      ),
    props: true,
    children: [
      {
        path: '',
        name: 'FinanceInvoices',
        redirect: '/financeInvoices/submitted',
      },
      {
        name: 'FinanceInvoicesSubmitted',
        path: 'submitted',
        component: () =>
          import(
            /* webpackChunkName: "FinanceInvoicesSubmitted" */ '@/views/FinanceInvoices/Submitted.vue'
          ),
        props: true,
        children: [
          {
            name: 'FinanceInvoicesSubmittedAdd',
            path: 'add',
            component: () =>
              import(
                /* webpackChunkName: "FinanceInvoicesSubmittedAdd" */ '@/views/FinanceInvoices/Edit.vue'
              ),
            props: { id: 0 },
          },
          {
            name: 'FinanceInvoicesSubmittedEdit',
            path: 'edit/:id',
            component: () =>
              import(
                /* webpackChunkName: "FinanceInvoicesSubmittedEdit" */ '@/views/FinanceInvoices/Edit.vue'
              ),
            props: true,
          },
          {
            name: 'FinanceInvoicesSubmittedDetail',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "FinanceInvoicesSubmittedDetail" */ '@/views/FinanceInvoices/Detail.vue'
              ),
            props: true,
          },
        ],
      },
      {
        name: 'FinanceInvoicesSubmittedSap',
        path: 'submittedSap',
        component: () =>
          import(
            /* webpackChunkName: "FinanceInvoicesSubmittedSap" */ '@/views/FinanceInvoices/SubmittedSap.vue'
          ),
        props: true,
        children: [
          {
            name: 'FinanceInvoicesSubmittedSapDetail',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "FinanceInvoicesSubmittedSapDetail" */ '@/views/FinanceInvoices/Detail.vue'
              ),
            props: true,
          },
        ],
      },
      {
        name: 'FinanceInvoicesEntered',
        path: 'entered',
        component: () =>
          import(
            /* webpackChunkName: "FinanceInvoicesEntered" */ '@/views/FinanceInvoices/Entered.vue'
          ),
        props: true,
        children: [
          {
            name: 'FinanceInvoicesEnteredDetail',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "FinanceInvoicesEnteredDetail" */ '@/views/FinanceInvoices/Detail.vue'
              ),
            props: true,
          },
        ],
      },
      {
        name: 'FinanceInvoicesRejected',
        path: 'rejected',
        component: () =>
          import(
            /* webpackChunkName: "FinanceInvoicesRejected" */ '@/views/FinanceInvoices/Rejected.vue'
          ),
        props: true,
        children: [
          {
            name: 'FinanceInvoicesRejectedDetail',
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "FinanceInvoicesRejectedDetail" */ '@/views/FinanceInvoices/Detail.vue'
              ),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: '/financePerson',
    name: 'FinancePerson',
    component: () =>
      import(/* webpackChunkName: "FinancePerson" */ '@/views/FinancePerson/'),
    props: true,
    children: [
      {
        name: 'FinancePersonDetail',
        path: ':id',
        component: () =>
          import(
            /* webpackChunkName: "FinancePersonDetail" */ '@/views/FinancePerson/Detail.vue'
          ),

        props: true,
      },
    ],
  },
];
