export default {
  app: "admin",
  backendServer: {
    dev: "http://localhost:9000/",
    test: "https://api2.gymkirchenfeld.ch/",
    prod: "https://api.gymkirchenfeld.ch/",
  },
  tokenRefreshOffsetMinutes: 10,
  msal: {
    clientId: "7ed32b4c-c253-43f5-a842-3418459e1470",
    tenantId: "6f499716-de74-4a5b-9a1e-806795a9e947",
  },
};
